<template>
    <section class="h-100 overflow-auto custom-scroll">
        <div class="row mx-0 justify-center">
            <card-producto-pedido
            v-for="(prod, idx) in productos" :key="`prod-${idx}`"
            :info="prod"
            class="cr-pointer"
            @accion="modalProducto"
            />
        </div>
        <modalDetalleProd ref="modalDetalleProd" />
        <modalProductoDesconocido ref="modalProductoDesconocido" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedido from '~/services/pedidos/pedidos-tienda'

export default {
    components: {
        modalDetalleProd: () => import('../../pedidos/partials/modalDetalleProducto'),
        modalProductoDesconocido: () => import('../../pedidos/partials/modalProductoDesconocido'),
    },
    data(){
        return {
            productos: []
        }
    },
    computed:{
        ...mapGetters({
            detalle: 'reclamos/reclamos/detalle',
            id_pedido: 'reclamos/reclamos/id_pedido',
        }),

    },
    mounted(){
        this.listar_productos()
    },
    methods: {
        modalProducto(prod){
            if(prod.estado === 3){
                this.$refs.modalProductoDesconocido.detalle_desconocido(prod)  // modal detalle desconocido
                return
            }
            this.$refs.modalDetalleProd.datos_cambio(prod) // modal detalle producto
        },
        async listar_productos(){
            try {
                if(this.id_pedido){
                    const {data} = await Pedido.pedidos_productos(this.id_pedido)
                    data.productos.map(el => {
                        el.idm_moneda = this.detalle.idm_moneda
                    })
                    this.productos = data.productos
                }
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
</style>
